import React from "react";
import WatchfacesGallery from "./WatchfacesGallery";
// import amplitudeEventsSender from "./utils/AmplitudeEvents";
import app_icon from "../images/app_icon.webp";
import { Mixpanel } from "./utils/MixPanelUtils";
import strap from "../images/15.webp"
import { preloadedFaces } from "./utils/utils";
import showMoreBtn from "../images/show_more_btn.webp"


class WatchfacesGalleryPanel extends React.Component {
    // const [images, setImages] = useState([]);

    state = {
        userName: null,
        faces: [],
        accessToken: null,
        facesUrl: null,
        activeMainModal: false,
        userToken: null,
        preloaded: {},
        previewComponentState: false,
        instructionPreviewState: false,
        preloadedFaces: preloadedFaces,
        isLoading: false,
    }

    componentDidMount() {
        this.getDictionaryOfImages();
        this.addNewClient();
    }

    getFbClientId = () => {
        let result = /_fbp=(fb\.\d\.\d+\.\d+)/.exec(window.document.cookie);
        if (!(result && result[1])) {
            return null;
        }
        return result[1];
    };


    addFacebookIdToClient = async () => {
        let fbClientId;
            setTimeout(async () =>{
                fbClientId = this.getFbClientId()
                if (fbClientId) {
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    };
                    const response = await fetch("/api/add_data_to_client", requestOptions);
                    const data = await response.json();
                    if (!response.ok) {
                        console.log("failed");
                    } else {
                        // console.log(data);
                    }
                }
            }, 3000);
    }

    addNewClient = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        }
        const response = await fetch('/api/add_new_client', requestOptions)
        const data = await response.json();
        if (!response.ok) {
            console.log("Failed to add new client");
        } else {
            // console.log(data.token);
            this.setState({
                userToken: data.token
            });
            // amplitudeEventsSender({event_name: 'page_loaded'});
            Mixpanel.identify(data.token);
            Mixpanel.track("gallery_page_loaded", {})
            this.addFacebookIdToClient();
        }
    }


    getDictionaryOfImages = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        };
        const response = await fetch("/api/get_images", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            // TODO: SHOW SOMETHING
            alert("Error!!");
        } else {
            this.setState({
                faces: data.faces,
                facesUrl: data.facesUrl,
            }, this.preloadImages);
        }
    };

    preloadImages = () => {
        const newPreloaded = {};
        Promise.all(
          this.state.preloadedFaces.map(async (face) => {
            const img = new Image();
            img.src = `${this.state.facesUrl}${face.face_name}.webp`;
            img.classList.add("preview-image");
            const strapImg = new Image();
            strapImg.src = strap;
            await Promise.all([img.decode(), strapImg.decode()]);
            newPreloaded[face.face_name] = {
              face: img,
              strap: strapImg
            };
          })
        ).then(() => {
          this.setState({
            preloaded: newPreloaded
          }, () => {
            // console.log(this.state.preloaded);
          });
        });
      };
      

    setCookie = (cname, cvalue, exdays) => {
        const expireDate = new Date();
        expireDate.setTime(expireDate.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + expireDate.toUTCString();
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }

    getCookie = (cname) => {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(";");
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    handleMainModal = () => {
        this.setState((prevState) => ({
          activeMainModal: false
        }));
      }
      
      setActiveMainModal = () => {
        this.setState((prevState) => ({
          activeMainModal: true
        }));
      }
      
      setPreviewComponentState = (state) => {
        this.setState((prevState) => ({
          previewComponentState: state
        }));
      }
      
      setInstructionPreviewState = (state) => {
        this.setState((prevState) => ({
          instructionPreviewState: state
        }));
      }

    showInstructionModal = () => {
        this.setPreviewComponentState(false);
        this.setInstructionPreviewState(true);
        this.setIsLoading(true);
        this.setActiveMainModal();
    }

    showMoreHandle = () => {
        const mobile = window.screen.width < 500 ? true : false;
        Mixpanel.track("show_more_clicked", {});
        if (mobile) {
            document.location.href = "https://apps.apple.com/pt/app/watch-faces-gallery-1/id1579079858?l=en-GB"
        } else {
            this.setActiveMainModal();
        }
    }

    setIsLoading = (state) => {
        this.setState((prevState) => ({
            isLoading: state
        }));
    }

    render() {
        return (
            <>
                <div className="container wfgallery" style={{maxWidth: "100%", height: "100%"}}>
                    <div className="ellipse ellipse-1"></div>
                    <div className="ellipse ellipse-2"></div>
                    <div className="ellipse ellipse-3"></div>
                    <div className="ellipse ellipse-4"></div>
                    <div className="ellipse ellipse-5"></div>
                    <div className="ellipse ellipse-6"></div>
                    <div className="ellipse ellipse-7"></div>
                    <div id="background-div">
                        <div className="pt-4 pb-2 wf-header">
                            <div className="mb-2 " style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={app_icon} alt="app icon" style={{ width: "30px" }} />
                                <h1 className="wf-title" style={{ marginLeft: "10px" }}>
                                    Watch Faces Gallery #1
                                </h1>
                                <div className="info-button" style={{ paddingLeft: "2rem", overflow: "visible" }} onClick={this.showInstructionModal}>
                                    <span className="info-icon">&#x24D8;</span>
                                </div>
                            </div>
                        </div>
                        <div style={{paddingBottom: "20px"}}>
                            <WatchfacesGallery 
                                // faces={this.state.faces ? this.state.faces : []}
                                faces={this.state.preloadedFaces}
                                facesUrl={this.state.facesUrl ? this.state.facesUrl : "https://facesgalleryapp.com/media/faces/"}
                                setMainModal={this.setActiveMainModal}
                                preloaded={this.state.preloaded}
                                // for modals
                                activeModalState={this.state.activeMainModal}
                                setActiveMainModal={this.setActiveMainModal}
                                handleModal={this.handleMainModal}
                                previewComponentState={this.state.previewComponentState}
                                setPreviewComponentState={this.setPreviewComponentState}
                                instructionPreviewState={this.state.instructionPreviewState}
                                setInstructionPreviewState={this.setInstructionPreviewState}
                                isLoading={this.state.isLoading}
                                setIsLoading={this.setIsLoading}
                            />
                        </div>
                        <div className="mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={showMoreBtn} alt="show more" style={{width: "55%", maxWidth: "200px"}} onClick={this.showMoreHandle}/>
                        </div>
                        <div className="container mt-6 pb-2">
                            <div className="row justify-content-center text-center mb-1">
                                <div className="mb-2 ml-4" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <img src={app_icon} alt="app icon" style={{ width: "40px" }} />
                                    <h1 className="wf-title" style={{ marginLeft: "10px" }}>
                                        Watch Faces Gallery #1
                                    </h1>
                                </div>
                                <div className="col-10 col-lg-12" style={{backgroundColor: "#DDE2E9", marginTop: "10px", height: "1px"}}>
                                    </div>
                                <div className="mb-2 ml-4" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <span className="text-center">
                                        All rights reserved 2023
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default WatchfacesGalleryPanel;
