import React from 'react';
import { getTokenValue } from "./utils";

export const pixelEventsSender = async (props) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            event_name: props.event_name,
        })
    };

    const response = await fetch('/tracking/send_pxl_event', requestOptions);
    const data = await response.json();
    if (! response.ok) {
        console.log("Error")
    } else {
        console.log(data.status);
    }
};

export const viewContentEvent = async () => {
    const external_id = getTokenValue();
    window.fbq('track', 'ViewContent', { external_id: external_id });
};

export const initiateCheckoutEvent = async () => {
    const external_id = getTokenValue();
    window.fbq('track', 'InitiateCheckout', { external_id: external_id });
};

export const LeadEvent = async () => {
    const external_id = getTokenValue();
    window.fbq('track', 'Lead', { external_id: external_id});
}