// import amplitudeEventsSender from "../utils/AmplitudeEvents";
// import { initiateCheckoutEvent } from "../utils/PixelEvents";
import {fetchFace} from "../utils/utils";
import { Mixpanel } from "../utils/MixPanelUtils";
import { LeadEvent } from "../utils/PixelEvents";

const DownloadWatchFaceBtn = ( props ) => {

    const downloadButtonStyle = {
        position: "relative",
        // top: "25vw",
        bottom: "-20vw",
        width: "210px",
        height: "51px",
        background: "#FFFFFF",
        borderRadius: "14px",
        fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "19px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        color: "#000000",
    }

    const downloadWatchFace = async () => {
        // amplitudeEventsSender({
        //     event_name: 'preview_add_button_pressed'
        // });
        Mixpanel.track("download_clicked", {face_id: props.faceName});
        LeadEvent();
        if (!window.localStorage.getItem("instructionShown")) {
            props.setInstructionPreviewState(true);
            window.localStorage.setItem("instructionShown", true);
            window.localStorage.setItem("readyToDownload", true);
        } else {
            props.setInstructionPreviewState(false)
            fetchFace(props.faceName);
            props.handleModal();
        }
        props.setPreview(false);
    };
    return (
        <button style={downloadButtonStyle} variant="contained" onClick={(e) => downloadWatchFace()}>
            Download Watch Face
        </button>
    );
};

export default DownloadWatchFaceBtn;
