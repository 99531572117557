import React, { useEffect, useState, useRef } from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import PreviewComponent from "../modal_components/PreviewComponent";
import InstructionComponent from "../modal_components/InstructionComponent";
import DesktopComponent from '../modal_components/DesktopComponent';


const MainModal = ( props ) => {

    const [previewComponentState, setPreviewComponentState] = useState(false);

    const mobile = window.screen.width < 500 ? true : false;

    const style = {
        position: "absolute",
        top: props.preview ? "65%" : (props.instructionPreviewState ? "60%" : "65%"),
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: "554px",
        height: "auto",
        minHeight: props.preview ? "85%" : (props.instructionPreviewState ? "100%" : "85%"),
        bgcolor: "background.paper",
        boxShadow: 24,
        background: "linear-gradient(158.53deg, #E8348F 0%, #3355F9 100%), #111111",
        borderRadius: props.preview ? "48px" : "48px 48px 0px 0px",
        outline: 0,
        justifyContent: "center",
    };

    const desktopStyle = {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        p: 4,
        outline: "none",
      };


    useEffect(() => {
        if (props.preview) {
            setPreviewComponentState(true);
        } else if (props.instructionPreviewState) {
            setPreviewComponentState(false);
        }
    }, [props.preview, props.instructionPreviewState, props]);

    const touchStartRef = useRef(null);

    const handleTouchStart = (event) => {
        touchStartRef.current = event.touches[0].clientY;
    };

    const handleTouchEnd = (event) => {
        const touchEnd = event.changedTouches[0].clientY;
        const touchStart = touchStartRef.current;
        const swipeThreshold = 100;
      
        if (touchEnd - touchStart > swipeThreshold) {
            const modalElement = document.querySelector(".MuiModal-root");
            const modalBackdrop = document.querySelector(".MuiBackdrop-root");
            if (modalBackdrop) {
                modalBackdrop.style.backgroundColor = "rgba(0, 0, 0, 0)";
            }
            if (modalElement) {
                modalElement.style.transition = "transform 0.5s ease-out";
                modalElement.style.transform = "translateY(100%)";
                setTimeout(() => {
                    handleClose();
                }, 500);
            }
        }
    };

    const handleClose = () => {
        props.handleModal();
        setPreviewComponentState(false);
    };

    return (
        <>
            {props.isLoading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {mobile ? (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={props.activeModalState}
                    // onClose={props.handleModal}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                >
                    <Fade in={props.activeModalState}>
                        <Box sx={style}>
                            <div
                            style={{
                                position: "absolute",
                                width: "80px",
                                height: "4.73px",
                                top: "15px",
                                background: "rgba(255, 255, 255, 0.3)",
                                borderRadius: "8px",
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                            ></div>
                            {previewComponentState ? (
                                <PreviewComponent
                                active={previewComponentState}
                                faceName={props.faceName}
                                setPreview={props.setPreview}
                                setReadyToBuy={props.setReadyToBuy}
                                setInstructionPreviewState={props.setInstructionPreviewState}
                                preloaded={props.preloaded}
                                facesUrl={props.facesUrl}
                                preview={props.preview}
                                handleModal={props.handleModal}
                                setIsLoading={props.setIsLoading}
                                />
                            ) : ( props.instructionPreviewState ? (
                                <InstructionComponent
                                faceName={props.faceName}
                                preview={props.preview}
                                handleModal={props.handleModal}
                                setIsLoading={props.setIsLoading}
                                />
                            ): (
                                <div></div>
                            ))}
                        </Box>
                    </Fade>
                </Modal>
                ) : (
                    <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={props.activeModalState}
                    onClose={props.handleModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                        <Fade in={props.activeModalState}>
                            <Box sx={desktopStyle}>
                                <DesktopComponent />
                            </Box>
                        </Fade>
                    </Modal>
                )}
        </>
    );
};

export default MainModal;