// from request getting all the images data we have in database (dicts)
// and get them from images directory to show
import { useState, useEffect } from "react";
import ImageComponent from "./ImageComponent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PreviewButton from "./buttons/PreviewButton";
import Item from "./style_components/Item";
import GridContainer from "./style_components/GridContainer";
import MainModal from "./modals/MainModal";
// import amplitudeEventsSender from "./utils/AmplitudeEvents";



const WatchfacesGallery = (props) => {

    const [faceName, setFaceName] = useState("");


    const handleModal = () => {
        // amplitudeEventsSender({
        //     event_name: 'preview_screen_closed',
        // });
        props.handleModal();
        props.setPreviewComponentState(false);
        props.setInstructionPreviewState(false);
        props.setIsLoading(false);
    };


    return (
        <>
            <MainModal
                activeModalState={props.activeModalState}
                setActiveMainModal={props.setActiveMainModal}
                handleModal={handleModal}
                preview={props.previewComponentState}
                setPreview={props.setPreviewComponentState}
                faceName={faceName}
                instructionPreviewState={props.instructionPreviewState}
                setInstructionPreviewState={props.setInstructionPreviewState}
                preloaded={props.preloaded}
                facesUrl={props.facesUrl}
                isLoading={props.isLoading}
                setIsLoading={props.setIsLoading}
            />
            <Box sx={{ flexGrow: 1 }}>
                {Object.entries(props.faces.reduce((groupedImages, image, index) => {
                    if (!groupedImages[image.category]) {
                    groupedImages[image.category] = [];
                    }
                    groupedImages[image.category].push(image);
                    return groupedImages;
                }, {})).map(([category, images]) => (
                    <div key={category}>
                    {/* Render the category name */}
                    <div className="mt-4 mb-3 ml-4" style={{ fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif", fontSize: "1.25em", fontWeight: "540", height: "25px" }}>{category}</div>

                    {/* Render the images within the category */}
                    <GridContainer container spacing={2}>
                        {images.map((image, index) => (
                            <Grid item xs={4} sm={1} md={2} lg={1} key={index}>
                            <Item>
                                <PreviewButton
                                    image={image}
                                    facesUrl={props.facesUrl}
                                    imageName={image.face_name}
                                    setActiveMainModal={props.setActiveMainModal}
                                    setPreviewComponentState={props.setPreviewComponentState}
                                    setFaceName={setFaceName}
                                    setIsLoading={props.setIsLoading}
                                />
                            </Item>
                        </Grid>
                        ))}
                    </GridContainer>
                    </div>
                ))}
            </Box>
        </>
    );
};

export default WatchfacesGallery;
