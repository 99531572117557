import React from 'react';
import ImageComponent from "../ImageComponent";
// import amplitudeEventsSender from "../utils/AmplitudeEvents";
import { Mixpanel } from '../utils/MixPanelUtils';
import {viewContentEvent} from "../utils/PixelEvents";

const PreviewButton = (props) => {

    const openPreviewModal = async () => {
        props.setIsLoading(true);

        props.setPreviewComponentState(true);
        props.setActiveMainModal(true);
        props.setFaceName(props.imageName.split(".")[0]);
        // amplitudeEventsSender({
        //     event_name: 'watch_face_pressed',
        //     properties: {
        //         // TODO: change to the local id for images
        //         faceID: props.imageName
        //     }
        // });
        Mixpanel.track("watchface_clicked", {face_id: props.imageName.split(".")[0]});
        viewContentEvent();
    }

    return (
        <div onClick={(e) => openPreviewModal()}>
            <ImageComponent
            image={props.image}
            facesUrl={props.facesUrl}
            />
        </div>
    );
};

export default PreviewButton;