import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled("div")(({ theme }) => ({
    color: "#ffffff",
    padding: theme.spacing(0.1),
    // paddingRight: "16px",
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
}));

export default Item;