import React, { useEffect } from 'react';
import DownloadWatchFaceBtn from "../buttons/DownloadWatchFaceBtn";
// import amplitudeEventsSender from "../utils/AmplitudeEvents"


class Container extends React.Component {
    containerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
    }

    strapStyle = {
        display: "block",
        maxHeight: "80%"
    }

    strap = this.props.strap;

    componentDidMount () {
        Object.assign(this.strap.style, this.strapStyle);
    }

    render() {
            return (
                <>
                    <div style={this.containerStyle} ref={ ref => ref ?  ref.append(this.strap, this.props.face) : "" }>
                    </div>
                </>
            )
    }
  }


const PreviewComponent = ( props ) => {

    useEffect(() => {
        props.setIsLoading(false);
    });


    const previewComponentStyle = {
        position: "relative",
        top: "14vw",
        boxSizing: "border-box",
        width: "70%",
        height: props.preview ? "calc(100% - 37px)" : "452px",
        background: "rgba(0, 0, 0, 0.28)",
        backdropFilter: "blur(5px)",
        borderRadius: props.preview ? "48px" : "48px 48px 0px 0px",
        margin: "0 auto",
        marginBottom: props.preview ? "0" : "40px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
    };


    return (
        <>
            <div id="transition-modal-title" style={{fontFamily: "Ubuntu"}} align="center" variant="h6" component="h2">
                <div style={Object.assign({}, previewComponentStyle, { height: "100%" })}>
                    {props.preloaded[props.faceName] && (
                        <>
                            <Container face={props.preloaded[props.faceName].face} strap={props.preloaded[props.faceName].strap}/>
                        </>
                    )}
                </div>
                <DownloadWatchFaceBtn
                    setReadyToBuy={props.setReadyToBuy}
                    setPreview={props.setPreview}
                    setInstructionPreviewState={props.setInstructionPreviewState}
                    faceName={props.faceName}
                    handleModal={props.handleModal}
                />
            </div>
        </>
    );
};

export default PreviewComponent;