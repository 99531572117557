import mixpanel from "mixpanel-browser";

mixpanel.init("4bdc8927303ea5b2c4b56bce0475f345");
// let env_check = process.env.NODE_ENV === 'production';

let actions = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    track: (event, properties) => {
        mixpanel.track(event, properties);
    }
}

export let Mixpanel = actions;