export const getTokenValue = () => {
    return document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
}

export const fetchFace = async (faceName) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const result = await fetch(`/api/get_face/${faceName}`, requestOptions);
    console.log(result);
    if (!result.ok) {
        console.log("LINK DOESN'T WORK!");
        return;
    }
    const blob_res = await result.blob();
    const href = window.URL.createObjectURL(blob_res);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${faceName}.watchface`);
    document.body.appendChild(link);
    link.setAttribute("target", "_blank");
    link.click();
    document.body.removeChild(link);
};

export const preloadedFaces = [
        {
            "face_name": "Duo_Casio_gray_activity",
            
            "category":"💛 Editor's Choice"
        },
        {
            "face_name": "WF_Duo_Everything_Multicolor",
            
            "category":"💛 Editor's Choice"
        },
        {
            "face_name": "WF_Duo_Gemstone",
            
            "category":"💛 Editor's Choice"
        },
        {
            "face_name": "WF_Modular_Custom_Weather_Blue",
            
            "category":"💛 Editor's Choice"
        },
        {
            "face_name": "WF_Modular_Custom_Weather_Red",
            
            "category":"💛 Editor's Choice"
        },
        {
            "face_name": "WF_Titanium_Weather",
            
            "category":"💛 Editor's Choice"
        },
        {
            "face_name": "WF_Duo_Terminal",
            
            "category": "🔥 New & Popular"
        },
        {
            "face_name": "WF_Modular_CustomWeather_Orange",
            
            "category": "🔥 New & Popular"
        },
        {
            "face_name": "WF_Modular_Everything_Multicolor",
            
            "category": "🔥 New & Popular"
        },
        {
            "face_name": "WF_Modular_Fitness",
            
            "category": "🔥 New & Popular"
        },
        {
            "face_name": "WF_Modular_HrZones",
            
            "category": "🔥 New & Popular"
        },
        {
            "face_name": "WF_Modular_Chrome_Orange",
            
            "category": "🔥 New & Popular"
        },
        {
            "face_name": "WF_Duo_Tactical_Multicolor_green",
            
            "category": "🎯 Tactical Color"
        },
        {
            "face_name": "WF_Duo_Tactical_Multicolor_yellow",
            
            "category": "🎯 Tactical Color"
        },
        {
            "face_name": "WF_Modular_Tactical_Circle_GrBlue",
            
            "category": "🎯 Tactical Color"
        },
        {
            "face_name": "WF_Modular_Tactical_Circle_Green",
            
            "category": "🎯 Tactical Color"
        },
        {
            "face_name": "WF_Modular_Tactical_Creamy",
            
            "category": "🎯 Tactical Color"
        },
        {
            "face_name": "WF_Modular_Tactical_Yellow",
            
            "category": "🎯 Tactical Color"
        },

        {
            "face_name": "WF_Duo_Weather_Chart",
            
            "category": "🌦️ Weather"
        },
        {
            "face_name": "WF_Modular_Weather_Fill",
            
            "category": "🌦️ Weather"
        },
        {
            "face_name": "WF_Modular_Weather",
            
            "category": "🌦️ Weather"
        },

        {
            "face_name": "WF_Duo_GlucoseSmart",
            
            "category": "❤️️ Health Care"
        },
        {
            "face_name": "WF_Duo_HrZones",
            
            "category": "❤️️ Health Care"
        },
        {
            "face_name": "WF_Modular_GlucoseSmart_Activity",
            
            "category": "❤️️ Health Care"
        },
        {
            "face_name": "WF_Modular_GlucoseSmart",
            
            "category": "❤️️ Health Care"
        },
        {
            "face_name": "WF_Modular_HrZones",
            
            "category": "❤️️ Health Care"
        },
        {
            "face_name": "WF_Duo_Chrome_Activity",
            
            "category": "🏃‍️ Chroma Activity"
        },
        {
            "face_name": "WF_Duo_Chrome_ActivityRed",
            
            "category": "🏃‍️ Chroma Activity"
        },
        {
            "face_name": "WF_Duo_Chrome_Orange",
            
            "category": "🏃‍️ Chroma Activity"
        },
        {
            "face_name": "WF_Modular_Chrome_ActivityRed",
            
            "category": "🏃‍️ Chroma Activity"
        },
        {
            "face_name": "WF_Modular_Chrome_Orange",
            
            "category": "🏃‍️ Chroma Activity"
        },
        {
            "face_name": "WF_Modular_Chrome_RGB",
            
            "category": "🏃‍️ Chroma Activity"
        },
        
    ];