import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";

import { fetchFace } from "../utils/utils";

import { textStyle } from "../style_components/styles";



const InstructionComponent = ( props ) => {

    useEffect(() => {
        props.setIsLoading(false);
    })
    
      const gotItBtnStyle = {
        position: "relative",
        width: "210px",
        height: "51px",
        background: "#FFFFFF",
        borderRadius: "14px",
        fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "19px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        color: "#000000",
        left: "50%",
        transform: "translateX(-50%)",
      };

      const imagesStyle = {
        position: "relative",
        display: "flex", 
        justifyContent: "center", 
        alignItems: 'center',
        width: "50%",
        left: "50%",
        transform: "translateX(-50%)",
    }

    const handleInstruction = () => {
        props.handleModal();
        if (window.localStorage.getItem("readyToDownload")) {
            window.localStorage.removeItem("readyToDownload");
            fetchFace(props.faceName);
        }
    }

    return (
        <div id="background" style={{justifyContent: "center"}}>
            <Typography id="transition-modal-title" variant="span">
                <div className="mt-5 ml-6 mr-6" style={textStyle}>
                    <p>Select the watch face you want to download and tap <span style={{color: "#FFDF70", fontWeight: "bold"}}>Download Watch Face</span> button.</p>
                </div>
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
                <div style={imagesStyle}>
                    <img src={require("../../images/instruction_btn.webp")} alt=""></img>
                </div>
            </Typography>
            <Typography id="transition-modal-title" variant="span">
                <div className="mt-2 ml-6 mr-6" style={textStyle}>
                    <p>In the action menu find and tap <span style={{color: "#FFDF70", fontWeight: "bold"}}>Watch app</span></p>
                </div>
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
                <div className="mt-2" style={imagesStyle}>
                    <img src={require("../../images/instruction_menu.webp")} alt=""></img>
                </div>
            </Typography>
            <Typography id="transition-modal-title" variant="span">
                <div className="mt-2 ml-6 mr-6" style={textStyle}>
                    <p>In the Watch app, tap the button <span style={{color: "#FFDF70", fontWeight: "bold"}}>Add to My Faces</span> and <span style={{color: "#FFDF70", fontWeight: "bold"}}>Install WatchOS App</span></p>
                </div>
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
                <div className="mt-2" style={imagesStyle}>
                    <img src={require("../../images/instruction_install_btn.webp")} alt=""></img>
                </div>
            </Typography>
            <button style={gotItBtnStyle} variant="contained" onClick={handleInstruction}>
                Got It
            </button>
        </div>
    );
};

export default InstructionComponent;
