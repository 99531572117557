import { useState, useEffect } from "react";

const ImageComponent = ({ image, facesUrl }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      {!imageLoaded && (
        <div
            style={{
                width: "105px",
                height: "129px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15.2274px",
                filter: "drop-shadow(6px 4px 14px rgba(0, 0, 0, 0.25))",
                border: "1px solid white",
            }}>
            <div className="loader"></div>
        </div>
      )}
        <img
            src={`${facesUrl}${image.face_name}.webp`}
            alt={image.face_name}
            style={{
                borderRadius: "15.2274px",
                filter: "drop-shadow(6px 4px 14px rgba(0, 0, 0, 0.25))",
                border: "1px solid white",
                minWidth: "105px",
                minHeight: "129px",
                visibility: imageLoaded ? "visible" : "hidden",
                }}
            onLoad={handleImageLoad}
        />
    </>
  );
};

export default ImageComponent;
