import React, {createContext, useState, useEffect} from "react";

export const AdminContext = createContext();

export const AdminProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem('argsth'));

    useEffect(() => {
        const fetchAdmin = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    "Content-Types": "application/json",
                    Authorization: 'Bearer ' + token,
                },
            };
            const response = await fetch("/admin/", requestOptions);

            if (!response.ok) {
                setToken(null);
            }
            localStorage.setItem('argsth', token);
        };
        if (document.location.href.includes("/admin")) {
            fetchAdmin();
        }
    }, [token]);

    return (
        <AdminContext.Provider value={[token, setToken]}>
            {props.children}
        </AdminContext.Provider>
    )
}