import React from 'react';
import {QRCodeCanvas} from "qrcode.react";
import Typography from "@mui/material/Typography";






const DesctopComponent = () => {
    const url = document.location.href;

    return (
        <>
            {/* <div className="mt-6" style={{display: "flex", justifyContent: "center", alignItems: 'center', color: "white", textAlign: "center"}}>
                <QRCodeCanvas
                    id="qrCode"
                    value={url}
                    size={150}
                    level={"H"}
                />
            </div> */}
            <Typography id="transition-modal-title" variant="h6" component="h2">
                <div>
                    <img src={require("../../images/scan_me.webp")} alt=""></img>
                </div>
            </Typography>
        </>
    );
};

export default DesctopComponent;