import React from "react";
import WatchfacesGalleryPanel from "./components/WatchfacesGalleryPanel";
import AdminPanel from "./components/AdminPanel";
import { Route, Routes } from "react-router-dom";
import {AdminProvider} from "./context/AdminContext"

const App = () => {

    return (
        <AdminProvider>
            <Routes>
                <Route path="/" element={<WatchfacesGalleryPanel />} />
                {/* <Route path="/admin" element={<AdminPanel />} /> */}
                {/* <Route path="/auth/facebook-login-callback/" element={<WatchfacesGalleryPanel />} /> */}
            </Routes>
        </AdminProvider>
    );
};

export default App;
